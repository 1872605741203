import styled from 'styled-components';
import { respondTo } from '../../../styles/shared/breakpoints';
import { teal, greyCopy, greyLighter, linkBlue, white } from '../../../styles/shared/colors';
import Button from '../Button';
import { WrapperEl } from '../../../styles/shared/layout';


export const WeliregFooter = styled.footer`
  background-color: ${white};
  padding-left: 0;

  ${respondTo.md`
    padding-left: 180px;
  `}

  ${respondTo.sm`
      margin: 0 auto;
  `}

  .ot-sdk-show-settings{
    border: none !important;
    padding: inherit !important;
    font-size: inherit !important;
    color: ${teal} !important;
    font-size: 14px !important;
    line-height: 14px !important;
    font-weight: bold !important;
    background-color: none !important;

  
      

      ${respondTo.sm`
        font-size: 18px !important;;
      `}

   
  }

  #ot-sdk-btn.ot-sdk-show-settings {
    &:hover {
      background-color: white !important;
    }
  }
`;

export const FooterWrapper = styled(WrapperEl)`
  padding-left: 20px;
  padding-right: 20px;
  ${respondTo.sm`
    padding: 36px 60px 64px 60px;
  `}
`;

export const FooterTop = styled.div`
  font-size: 12px;
  line-height: 1.25;
  word-break: break-word;
  & i {
    font-style: italic;
  }
  & span {
word-break: break-all;
}
`;

export const FooterBottom = styled.div`
  padding: 31px 0 0 0;
  display: flex;
  flex-direction: column;
  ${respondTo.sm`
      padding: 31px 0 0 0;
      flex-direction: column;
  `}
`;

export const PrivacyLinks = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 50px;

  ${respondTo.sm`
    gap: 3em;
    margin-bottom: unset;
  `}
`;

export const MerckLogo = styled.img`
  display: inline-block;
  flex: 0 0 auto;
  height: 35px;
  width: auto;
`;

export const BottomRightFlex = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;

  ${respondTo.sm`
    flex-direction: row;
  `}
`;

export const LegalLinkRow = styled.div`
  display: flex;
  flex-direction: column;

  ${respondTo.sm`
    width: 35%;
    padding-top: 8px;
    padding-right: 6px;
  `}
 
  span {
    display: inline-block;
    margin-bottom: 18px;

    a {
      text-decoration: none;
      color: ${teal};
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      &:hover {
        cursor: pointer;
        background-color: unset;
      }

      ${respondTo.sm`
        font-size: 18px;
      `}
    }
  } 
`;

export const CopyrightRow = styled.div`
  margin-bottom: 25px;


  ${respondTo.sm`
    margin-bottom: 21px;
  `}
  p {
    margin: 0;
    font-size: 14px;
    line-height: 20px;
    color: ${greyCopy};

  }
`;

export const siteLink = styled(Button)`
  padding-top: 0;
  padding-bottom: 0;
  font-size: 14px;
  line-height: 20px;
  color: ${linkBlue};
  padding-left: 18px;
  .arrow-left-inverse{
    filter: invert(29%) sepia(65%) saturate(4264%) hue-rotate(194deg) brightness(89%) contrast(76%);
    width: 9px;
    height: 9px;
  }
  margin: 0 0 20px 0;
  &:last-child {
    margin: 0 0 32px 0;
    ${respondTo.sm`
      margin: 0 35px 0 0;
    `}
  }
  ${respondTo.sm`
    margin: 0 35px 0 0;
  `}
  &:hover {
    text-decoration: none;
  }
`;


export const HcpButton = styled(Button)`
  padding: 6px 17px 8px 14px;
  margin: 0 auto;
  font-size: 13px;
  line-height: 20px;
  ${respondTo.sm`
    margin: 0;
    &:hover {
      background: ${linkBlue};
      color: #ffffff;
    }
  `}
`;

export const LegalLink = styled(Button)`
  margin: 0 16px;
  color: ${linkBlue};
  font-size: 16px;
  font-weight: bold;
  line-height: 30px;
  padding: 0;
  white-space: nowrap;

  &:after {
    content: '•';
    position: absolute;
    left: -4vw;
  }

  ${respondTo.sm`
    font-size: 14px;
    line-height: 20px;
    &:after {
      left: -16px;
      transform: translateX(-50%);
    }
  `}

 

  &:first-child {
    margin-left: 0;
    &:before {
      display: none;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`;


export const CookieButton = styled(Button)`
  padding-left: 0;
  padding-right: 0;
  padding-bottom: 0;
`;

export const MerckText = styled.div`

`;
