import React from 'react';
import logoMerck from '../assets/images/logo-merck.svg';
import MerckLogoText from '../assets/images/hcp-neo-merck-oncology-logo.svg'

const footerCopy = {
  sitemapLinks: [
    {
      label: 'What is VHL disease?',
      href: '/what-is-vhl/',
    },
    {
      label: 'VHL and genetics',
      href: '/vhl-and-genetics/',
    },
    {
      label: 'How is VHL diagnosed?',
      href: '/how-is-vhl-diagnosed/',
    },
  ],

  legalLinks: [
    {
      label: 'Terms of Use',
      url: 'https://www.merck.com/terms-of-use/',
      type: 'tertiary',
      target: '_blank',
      dataDesignCategory: 'bottom_nav_vd',
      href:'',
      id: '',
      className: ''
    },
    {
      label: 'Privacy Policy',
      url: 'https://www.msdprivacy.com/us/en/index.html',
      type: 'tertiary',
      dataDesignCategory: 'bottom_nav_vd',
      target: '_blank',
      href:'',
      id: '',
      className: ''
    },
    {
      label: 'Cookie Preferences',
      type:'tertiary',
      href:"javascript:void(0)",
      dataDesignCategory:'bottom_nav_vd',
      id: 'ot-sdk-btn',
      className: 'ot-sdk-show-settings'
    },
   
  ],

  merckLogo: logoMerck,
  merckLogoText: MerckLogoText
};


export default footerCopy;
